const employeeColumn = () => [
	{ field: 'id', headerName: 'ID', width: 90 },
	{
		field: 'firstName',
		headerName: 'First name',
		width: 150,
		editable: true,
	},
	{
		field: 'lastName',
		headerName: 'Last name',
		width: 150,
		editable: true,
	},
	{
		field: 'email',
		headerName: 'Email',
		width: 110,
		editable: true,
	},
	{
		field: 'contactMobileNumber',
		headerName: 'Phone Number',
		width: 110,
		editable: true,
	},
	{
		field: 'gender',
		headerName: 'Gender',
		width: 110,
		editable: true,
	},
	{
		field: 'tncAgreed',
		headerName: 'T&C Status',
		width: 110,
		editable: true,
	},
	{
		field: 'orgType',
		headerName: 'Organisation Type',
		width: 110,
		editable: true,
	},
	{
		field: 'dob',
		headerName: 'Date of Birth',
		width: 110,
		editable: true,
	},
	{
		field: 'address',
		headerName: 'Address',
		width: 110,
		editable: true,
	},
];

export default employeeColumn;
