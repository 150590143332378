import { Box } from '@mui/material';
import CustomTable from 'components/common/CustomTable';
import Heading from 'components/common/Heading';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import employee from 'services/employee';

import employeeColumn from './employeeColumn';

const Employees = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { employeeData } = useSelector((state: RootState) => state.employee);
	useEffect(() => {
		dispatch(employee());
	}, []);

	return (
		<main>
			<Box marginY={2}>
				<Heading heading='Employees' />
			</Box>
			<Box marginY={3}>
				<CustomTable height='70vh' rows={employeeData} columns={employeeColumn()} />
			</Box>
		</main>
	);
};

export default Employees;
