import * as Yup from 'yup';

const loginValidator = Yup.object({
	email: Yup.string().email('Invalid email').required('Email required'),
	password: Yup.string()
		.required('Password required')
		.min(8, 'Password is too short - should be 8 characters minimum.'),
});

export default loginValidator;
