import { Box } from '@mui/material';
import CustomTable from 'components/common/CustomTable';
import Heading from 'components/common/Heading';
import { columns, rows } from 'views/Dashboard';

const Tickets = () => (
	<main>
		<Box marginY={2}>
			<Heading heading='Tickets' />
		</Box>
		<Box marginY={3}>
			<CustomTable height='70vh' rows={rows} columns={columns} />
		</Box>
	</main>
);

export default Tickets;
