import appUrls from 'constants/urls';
import AuthLayout from 'layout/Auth';
import CommonLayout from 'layout/Common';
import Login from 'views/auth/Login';
import SignUp from 'views/auth/Signup';
import Company from 'views/Companies';
import Dashboard from 'views/Dashboard';
import Employee from 'views/Employees';
import Profile from 'views/Profile';
import Ticket from 'views/Tickets';
import Users from 'views/Users';

//  make a route constant file move all constant over there
const routes = [
	{
		layout: AuthLayout,
		path: '',
		isPrivate: false,
		children: [
			{
				component: Login,
				path: appUrls.base,
				isPrivate: false,
			},
			{
				component: SignUp,
				path: appUrls.signup,
				isPrivate: false,
			},
		],
	},
	{
		layout: CommonLayout,
		path: '',
		isPrivate: true,
		children: [
			{
				component: Dashboard,
				path: appUrls.dashboard,
				isPrivate: true,
			},
			{
				component: Company,
				path: appUrls.companies,
				isPrivate: true,
			},
			{
				component: Users,
				path: appUrls.users,
				isPrivate: true,
			},
			{
				component: Employee,
				path: appUrls.employees,
				isPrivate: true,
			},
			{
				component: Ticket,
				path: appUrls.tickets,
				isPrivate: true,
			},
			{
				component: Profile,
				path: appUrls.profile,
				isPrivate: true,
			},
		],
	},
];

export default routes;
