import { Box } from '@mui/material';
import Heading from 'components/common/Heading';

const Users = () => (
	<main>
		<Box marginY={2}>
			<Heading heading='Profile' />
		</Box>
	</main>
);

export default Users;
