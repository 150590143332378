import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstanceEmployee } from 'config/axiosInstance';
import { getEmployeesUrl } from 'utils/apiEndpoints';

const employee = createAsyncThunk('employee', async () => {
	try {
		const res: any = await axiosInstanceEmployee.get(getEmployeesUrl);
		if (res?.success) {
			return res?.data;
		}
		throw res;
	} catch (error: any) {
		return error;
	}
});
export default employee;
