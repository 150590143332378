const appUrls = {
	base: '/',
	signup: '/signup',
	dashboard: '/dashboard',
	companies: '/companies',
	users: '/users',
	employees: '/employees',
	tickets: '/tickets',
	profile: '/profile',
};
export default appUrls;
